<template>
  <!-- 新建异常 -->
  <div class="container">
    <el-tabs
      v-model="activeName"
      type="card"
      @tab-click="handleClick"
      :before-leave="beforeLeave"
    >
      <el-tab-pane :label="labelTitle" name="first">
        <div class="formBox">
          <div class="btns">
            <el-button type="warning" size="mini" @click="addAbnormal"
              >保 存</el-button
            >
            <el-button
              v-if="isShow"
              size="mini"
              plain
              @click="resetForm('abnormalForm')"
              >重 置</el-button
            >
          </div>
          <!-- <div class="_header" v-if="isShow">
            <div>快递单号：</div>
            <el-select
              v-model="cn"
              filterable
              remote
              reserve-keyword
              value-key="courierNumber"
              placeholder="请输入关键词"
              :remote-method="remoteMethod"
              @change="SE"
              :loading="loading"
            >
              <el-option
                v-for="item in expressNumArr"
                :key="item.courierNumber"
                :label="item.courierNumber"
                :value="item"
              >
              </el-option>
            </el-select>

            <el-button
              type="warning"
              size="small"
              style="margin-left: 20px"
              @click="QE"
              >查 询</el-button
            >
          </div> -->
          <div class="newForm">
            <el-form
              ref="abnormalForm"
              :model="abnormalForm"
              :rules="abnormalRules"
              label-width="120px"
              class="addFormClass"
            >
              <div class="queryItem1">
                <el-form-item label="单据类型:" prop="documentStatus">
                  <el-select
                    v-model="abnormalForm.documentStatus"
                    placeholder="请选择"
                    clearable
                    filterable
                    @change="changeDocumentType"
                  >
                    <el-option
                      v-for="item in documentTypeArr"
                      :key="item.statusVal"
                      :label="item.showName"
                      :value="item.statusVal"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <!-- <el-form-item label="来源单号:" prop="documentNumber">
                  <el-input
                    v-model="abnormalForm.documentNumber"
                    placeholder="自动填充"
                    readonly
                  ></el-input>
                </el-form-item>   -->
                <el-form-item label="来源单号:" prop="documentNumber">
                  <el-select
                    v-model="abnormalForm.documentNumber"
                    placeholder="请选择"
                    clearable
                    filterable
                    @change="changeDocNumgber"
                    remote
                    :remote-method="getDocumentNumber"
                  >
                    <el-option
                      v-for="item in SourceDocNoList"
                      :key="item.documentNumber"
                      :label="item.documentNumber"
                      :value="item.documentNumber"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="queryItem1">
                <el-form-item label="会员号:" prop="userMemberNumber">
                  <el-input
                    v-model="abnormalForm.userMemberNumber"
                    placeholder="自动填充"
                    readonly
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="会员名称:" prop="userMemberName">
                  <el-input
                    v-model="abnormalForm.userMemberName"
                    placeholder="自动填充"
                    readonly
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="会员简称:" prop="userMemberTab	">
                  <el-input
                    v-model="abnormalForm.userMemberTab"
                    placeholder="自动填充"
                    readonly
                  ></el-input>
                </el-form-item>
              </div>
              <!-- <div class="queryItem1">
                <el-form-item label="货物类型:" prop="categoryName">
                  <el-input
                    v-model="abnormalForm.categoryName"
                    placeholder="自动填充"
                    readonly
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="品名:" prop="itemNames">
                  <el-input
                    v-model="abnormalForm.itemNames"
                    placeholder="自动填充"
                    readonly
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="单价:" prop="unitPriceAll">
                  <el-input
                    v-model="abnormalForm.unitPriceAll"
                    placeholder="自动填充"
                    readonly
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="数量:" prop="quantityAll">
                  <el-input
                    v-model="abnormalForm.quantityAll"
                    placeholder="自动填充"
                    readonly
                  ></el-input>
                </el-form-item>
              </div> -->
              <div class="queryItem1">
                <el-form-item label="异常类型:" prop="exceptionTypeName">
                  <el-select
                    v-model="abnormalForm.exceptionTypeName"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in abnormalTypeArr"
                      :key="item.id"
                      :label="item.exceptionName"
                      :value="item.exceptionName"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="queryItem1">
                <el-form-item label="申请人:" prop="applyUserId">
                  <el-select
                    v-model="abnormalForm.applyUserId"
                    placeholder="请选择"
                    filterable
                    clearable
                    remote
                    :remote-method="userEmployeeList"
                    @change="changeEmploy"
                  >
                    <el-option
                      v-for="item in employeeNameList"
                      :key="item.employeeId"
                      :label="item.employeeName"
                      :value="item.employeeId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="处理部门:" prop="storageId">
                  <el-select
                    v-model="abnormalForm.storageId"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in cangkuArr"
                      :key="item.id"
                      :label="item.storageName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="queryItem1" style="width: 100%">
                <el-form-item label="异常描述:" prop="comment">
                  <el-input
                    type="textarea"
                    v-model="abnormalForm.comment"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div class="picBox">
            <div style="margin-bottom: 10px; color: #909090">上传图片：</div>
            <el-upload
              class="upload-demo"
              :action="activeUrl"
              list-type="picture-card"
              :limit="limitCount"
              :accept="'image/*'"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :http-request="uploadSectionFile"
              :file-list="fileList"
              :class="{ hide: hideUpload }"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </div>
      </el-tab-pane>
      <!-- 异常处理 -->
      <el-tab-pane label="异常处理" name="second">
        <div class="formBox">
          <div class="btns">
            <el-button type="warning" size="mini" @click="dealSave"
              >保 存</el-button
            >
          </div>
          <div class="newForm">
            <el-form
              :model="dealAbnormalForm"
              label-width="120px"
              class="addFormClass"
              :rules="rulesCL"
            >
              <!-- <div class="queryItem1">
                <el-form-item label="退货单号:" prop="returnOrderNo">
                  <el-input
                    placeholder="请输入"
                    v-model="dealAbnormalForm.returnOrderNo"
                  ></el-input>
                </el-form-item>
              </div> -->
              <div class="queryItem1">
                <el-form-item label="处理人:" prop="handlerUserId">
                  <el-select
                    v-model="dealAbnormalForm.handlerUserId"
                    placeholder="请选择"
                    filterable
                    clearable
                    remote
                    :remote-method="userEmployeeList"
                    @change="changeEmploy2"
                    @focus="userEmployeeList()"
                  >
                    <el-option
                      v-for="item in employeeNameList"
                      :key="item.employeeId"
                      :label="item.employeeName"
                      :value="item.employeeId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="处理时间:" prop="handleTime">
                  <el-date-picker
                    v-model="dealAbnormalForm.handleTime"
                    type="datetime"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="处理类型:" prop="handleTypeName">
                  <el-select
                    v-model="dealAbnormalForm.handleTypeName"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in dealType"
                      :key="item.exceptionHandleTypeId"
                      :label="item.handleName"
                      :value="item.exceptionHandleTypeId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="处理状态:" prop="isHandle">
                  <el-select
                    v-model="dealAbnormalForm.isHandle"
                    placeholder="请选择"
                  >
                    <el-option label="未处理" :value="0"></el-option>
                    <el-option label="已处理" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="是否需要赔偿:" prop="isIndemnity">
                  <el-select
                    v-model="dealAbnormalForm.isIndemnity"
                    placeholder="请选择"
                  >
                    <el-option label="不需要" :value="0"></el-option>
                    <el-option label="需要" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="queryItem1" v-if="dealAbnormalForm.isIndemnity">
                <el-form-item label="赔偿金额:">
                  <el-input
                    type="number"
                    placeholder="请输入"
                    v-model="dealAbnormalForm.compensationAmount"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="是否需要退货:" prop="isReturnGoods">
                  <el-select
                    v-model="dealAbnormalForm.isReturnGoods"
                    placeholder="请选择"
                  >
                    <el-option label="不需要" :value="0"></el-option>
                    <el-option label="需要" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="收件人:">
                  <el-input
                    placeholder="请输入"
                    v-model="dealAbnormalForm.consigneeName"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="收件电话:">
                  <el-input
                    placeholder="请输入"
                    v-model="dealAbnormalForm.consigneePhone"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="快递金额:">
                  <el-input
                    placeholder="请输入"
                    v-model="dealAbnormalForm.expressAmount"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="快递公司:">
                  <el-input
                    placeholder="请输入"
                    v-model="dealAbnormalForm.expressCompany"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="退货物流单号:">
                  <el-input
                    placeholder="请输入"
                    v-model="dealAbnormalForm.courierNumber"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1" style="width: 100%">
                <el-form-item label="退货地址:">
                  <el-input
                    placeholder="请输入"
                    type="textarea"
                    v-model="dealAbnormalForm.consigneeAddress"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="queryItem1" style="width: 100%">
                <el-form-item label="处理描述:">
                  <el-input
                    placeholder="请输入"
                    type="textarea"
                    v-model="dealAbnormalForm.handleComment"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div class="picBox" style="margin-top: 0px">
            <div style="margin-bottom: 10px; color: #909090">处理图片：</div>
            <el-upload
              class="upload-demo"
              :action="activeUrl"
              list-type="picture-card"
              :limit="limitCount"
              :accept="'image/*'"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :http-request="uploadSectionFile"
              :file-list="fileList_deal"
              :class="{ hide: hideUpload }"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </div>
      </el-tab-pane>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </el-tabs>
  </div>
</template>
<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";

export default {
  data() {
    return {
      activeName: "first",
      abnormalForm: {
        comment: "",
        exceptionTypeName: "",
        packageId: "",
        pics: [],
        storageId: "",
        applyUserId: "",
        documentNumber: "",
        documentStatus: "",
        userMemberTab: "",
        userMemberNumber: "",
        userMemberName: "",
        userMemberId: "",
        isHandle: "",
      },
      dealAbnormalForm: {
        compensationAmount: "",
        consigneeAddress: "",
        consigneeName: "",
        consigneePhone: "",
        courierNumber: "",
        exceptionId: "",
        expressAmount: "",
        expressCompany: "",
        handleComment: "",
        handleTime: "",
        handleTypeName: "",
        handlerUserId: "",
        isHandle: "",
        isIndemnity: "",
        isReturnGoods: "",
        pics: [],
        returnOrderNo: "",
      },
      //     图片上传
      fileList: [],
      fileList_deal: [],
      activeUrl: "",
      limitCount: 10,
      dialogImageUrl: "",
      dialogVisible: false,
      hideUpload: false,
      expressNumEdit: undefined,
      cn: "", //  快递单号
      loading: false, //
      expressNumArr: [],
      singleData: {}, //  快递单查询框选中的数据
      cangkuArr: [], //  仓库下拉数组
      abnormalTypeArr: [], //  异常类型下拉数组
      abnormalRules: {
        courierNumber: [
          { required: true, message: " ", trigger: ["blur", "change"] },
        ],
        exceptionTypeName: [
          { required: true, message: " ", trigger: ["blur", "change"] },
        ],
        storageId: [
          { required: true, message: " ", trigger: ["blur", "change"] },
        ],
      },
      rulesCL: {
        handleTypeName: [
          { required: true, message: "请输入 ", trigger: ["blur", "change"] },
        ],
        handleTime: [
          { required: true, message: "请输入 ", trigger: ["blur", "change"] },
        ],
        isIndemnity: [
          { required: true, message: "请输入 ", trigger: ["blur", "change"] },
        ],
        isReturnGoods: [
          { required: true, message: "请输入 ", trigger: ["blur", "change"] },
        ],
        isHandle: [
          { required: true, message: "请输入 ", trigger: ["blur", "change"] },
        ],
      },
      packageExceptionId: undefined, //  异常包裹修改携带的id
      isShow: true,
      //     异常处理
      dealType: [],
      labelTitle: "新建异常",
      documentTypeArr: [],
      SourceDocNoList: [],
      employeeNameList: [],
      adminUserLists: [],
    };
  },
  created() {
    //  新建异常两种方式：页面输入快递单号拿包裹Id,外部传入快递单号
    this.activeUrl = this.common.uploadUrl;
    this.expressNumEdit = this.$route.query.num; //  获取外部传来的快递单号
    if (this.$route.query.id) {
      //  新建异常修改
      this.labelTitle = "修改异常";
      this.packageExceptionId = this.$route.query.id; //  获取异常包裹id（修改时用）
      this.isShow = false; //  隐藏快递单号查询
      this.getUpdataDetail(); //  获取异常包裹详情
    }
    if (this.$route.query.editId) {
      //  异常处理
      this.activeName = "second";
      this.packageExceptionId = this.$route.query.editId;
      this.dealAbnormalForm.exceptionId = this.$route.query.editId;
      // this.dealAbnormalForm.returnOrderNo = this.$route.query.documentNumber;
      // this.getDealDetail(); //  获取异常处理详情
      this.getDealTypeList(); //  获取异常处理类型
    }
    this.getCangkuArr(); //  获取仓库下拉数组
    this.getAbnormalList(); //  异常类型

    // 单据类型
    this.$comAPI
      .getStatusValList("bill_package_exception_v1.document_status")
      .then((res) => {
        this.documentTypeArr = res || [];
      });

    this.userEmployeeList();
  },
  methods: {
    // 员工下拉列表
    userEmployeeList(val) {
      Api.userEmployeeList({
        employeeName: val || "",
      }).then((res) => {
        this.employeeNameList = res.data.result.data || [];
      });
    },
    // 账号下拉列表
    adminUserList(val) {
      Api.adminUserList({
        employeeName: val || "",
      }).then((res) => {
        this.adminUserLists = res.data.result.data || [];
      });
    },
    // 选择员工
    changeEmploy(e) {
      console.log(e);
      this.employeeNameList.forEach((item) => {
        if (item.employeeId == e) {
        }
      });
    },
    // 选择账号
    changeEmploy2(e) {
      // this.employeeNameList.forEach((item) => {
      //   if (item.employeeId == e) {
      //   }
      // });
    },

    changeDocumentType(e) {
      this.abnormalForm.documentNumber = "";
      this.SourceDocNoList = [];
      if (e == "package_v1:document_status") {
        // 包裹单
        this.packageList();
      } else if (e == "bill_pack_v1:document_status") {
        // 申请打包单
        this.getPackPackagetListV2();
      }
    },
    changeDocNumgber(e) {
      console.log(e);
      if (this.abnormalForm.documentStatus == "package_v1:document_status") {
        // 包裹单
        this.SourceDocNoList.forEach((item) => {
          if (item.courierNumber == e) {
            this.abnormalForm.userMemberId = item.userMemberId;
            this.abnormalForm.userMemberNumber = item.memberId;
            this.abnormalForm.userMemberName = item.memberNickname;
            this.abnormalForm.userMemberTab = "";
          }
        });
      } else if (
        this.abnormalForm.documentStatus == "bill_pack_v1:document_status"
      ) {
        // 申请打包单
        this.SourceDocNoList.forEach((item) => {
          if (item.billPackNumber == e) {
            this.abnormalForm.userMemberId = item.userMemberId;
            this.abnormalForm.userMemberNumber = item.memberId;
            this.abnormalForm.userMemberName = item.nickname;
            this.abnormalForm.userMemberTab = "";
          }
        });
      }
    },

    // 来源单远程搜索
    getDocumentNumber(e) {
      if (!this.abnormalForm.documentStatus) {
        this.$message.error("请先选择单据类型");
        return false;
      }
      if (this.abnormalForm.documentStatus == "package_v1:document_status") {
        // 包裹单
        this.packageList(e);
      } else if (
        this.abnormalForm.documentStatus == "bill_pack_v1:document_status"
      ) {
        // 申请打包单
        this.getPackPackagetListV2(e);
      }
    },

    // 获取申请打包单列表
    getPackPackagetListV2(val) {
      // let param = [];
      // if (val) {
      //   param = [val];
      // }
      Api.getPackPackagetListV2({
        billPackNumber: val,
        pageTotal: 100,
      }).then((res) => {
        console.log(res.data.result);
        let arrs = res.data.result.data || [];
        //
        arrs.forEach((item) => {
          item.documentNumber = item.billPackNumber;
        });

        this.SourceDocNoList = arrs;
      });
    },
    // 获取包裹单列表
    packageList(val) {
      let param = [];
      if (val) {
        param = [val];
      }
      Api.packageList({
        courierNumber: param,
        pageStart: 1,
        pageTotal: 100,
      }).then((res) => {
        let arrs = res.data.result.data || [];
        //
        arrs.forEach((item) => {
          item.documentNumber = item.courierNumber;
        });

        this.SourceDocNoList = arrs;
      });
    },

    remoteMethod(query) {
      //  select框远程搜索
      if (query !== "") {
        this.loading = true;
        Api.getExpressNumData({ courierNumber: query }).then((res) => {
          if (res.data.status === "success") {
            this.loading = false;
            this.expressNumArr = res.data.result;
          }
        });
      } else {
        this.expressNumArr = [];
      }
    },
    QE() {
      //   添加按钮
      this.abnormalForm.packageId = this.singleData.packageId;
      this.getPackageDetail(this.singleData.courierNumber); //  获取快递包裹详情
    },
    SE(val) {
      //  快递单号下拉框选中函数
      this.singleData = val;
    },
    getPackageDetail(num) {
      //  获取快递包裹详情
      Api.abnormalPackageDetail({ courierNumber: num }).then((res) => {
        if (res.data.status === "success") {
          let _data = {};
          _data = res.data.result;
          _data.packageId = this.abnormalForm.packageId;
          this.abnormalForm = _data;
        }
      });
    },

    getCangkuArr() {
      //  获取仓库下拉
      Api.getStorageList().then((res) => {
        if (res.data.status === "success") {
          this.cangkuArr = res.data.result;
        }
      });
    },
    getAbnormalList() {
      //  异常类型
      Api.abnormalSList().then((res) => {
        if (res.data.status === "success") {
          this.abnormalTypeArr = res.data.result;
        } else {
          console.log(`${res.data.message}--异常类型`);
        }
      });
    },
    handleClick(val) {
      //  tab切换
      if (val.name === "first") {
        this.resetForm("abnormalForm");
      } else if (val.name === "second") {
        if (this.packageExceptionId) {
          // this.getDealDetail(); //  获取异常处理详情
          this.getDealTypeList(); //  获取异常处理类型
        }
      }
    },
    beforeLeave(val) {
      //  阻止tab切换
      if (!this.packageExceptionId && val === "second") {
        return false;
      }
    },

    getUpdataDetail() {
      //  获取异常包裹详情
      // Api.packageExceptionDetail({
      Api.exceptionDetailV2({
        packageExceptionId: this.packageExceptionId,
      }).then((res) => {
        if (res.data.status === "success") {
          if (res.data.result.fileList !== null) {
            res.data.result.fileList.forEach((item) => {
              this.fileList.push({
                name: item.fileId,
                url: item.url,
              });
            });
          }
          this.abnormalForm = res.data.result;
        } else {
          // this.$message.error(res.data.message);
        }
      });
    },
    //    ------------ 上传图片 ---------------
    handleRemove(file, fileList) {
      //  图片上传事件
      /* 移除图片 */
      if (this.activeName === "first") {
        this.fileList = fileList;
      } else {
        this.fileList_deal = fileList;
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    uploadSectionFile(param) {
      // 上传图片
      const file = param.file;
      const fileName = file.name;
      const form = new FormData(); //  根据后台需求数据格式
      form.append("file", file); //  文件对象
      form.append("fileName", fileName); //  本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      Api.uploadFile(form).then((res) => {
        if (res.data.status === "success") {
          if (this.activeName === "first") {
            this.fileList.push({
              name: res.data.result.fileName,
              uid: res.data.result.redirectUrl,
              url: res.data.result.url,
            });
          } else {
            this.fileList_deal.push({
              name: res.data.result.fileName,
              uid: res.data.result.redirectUrl,
              url: res.data.result.url,
            });
          }
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    resetForm(formName) {
      //  重置表单
      this.$refs[formName].resetFields();
      this.fileList = [];
      this.cn = "";
    },
    addAbnormal() {
      //  新建异常
      this.$refs["abnormalForm"].validate((valid) => {
        if (valid) {
          let _data = {
            applyUserId: this.abnormalForm.applyUserId || "",
            comment: this.abnormalForm.comment || "",
            documentNumber: this.abnormalForm.documentNumber || "",
            documentStatus: this.abnormalForm.documentStatus || "",
            exceptionTypeName: this.abnormalForm.exceptionTypeName || "",
            isHandle: this.abnormalForm.isHandle || 0,
            packageId: this.abnormalForm.packageId || "",
            storageId: this.abnormalForm.storageId || "",
            userMemberId: this.abnormalForm.userMemberId || "",
            userMemberName: this.abnormalForm.userMemberName || "",
            userMemberNumber: this.abnormalForm.userMemberNumber || "",
            userMemberTab: this.abnormalForm.userMemberTab || "",
          };
          _data.pics = [];
          this.fileList.forEach((item) => {
            _data.pics.push(item.name);
          });
          if (!this.packageExceptionId) {
            // 新增
            _data.sign = tools.getSign(_data);
            // Api.addExceptionPackage(_data).then((res) => {
            Api.addExceptionV2(_data).then((res) => {
              this.$message.success(res.data.message || "操作成功");
              this.resetForm("abnormalForm");
            });
          } else {
            // 修改
            _data.exceptionId = this.packageExceptionId;
            _data.sign = tools.getSign(_data);
            Api.updateExceptionV2(_data).then((res) => {
              if (res.data.status === "success") {
                this.$message.success(res.data.message || "");
                setTimeout(() => {
                  this.$router.push("/exceptionhandling/Abnormalpackage");
                }, 1000);
              }
            });
          }
        } else {
          this.$message.warning("请完整填写表单");

          return false;
        }
      });
    },

    //    异常处理
    getDealDetail() {
      //  异常处理详情
      Api.exceptionHandleDetailV2({
        exceptionId: this.packageExceptionId,
      }).then((res) => {
        if (res.data.status === "success") {
          let datas = res.data.result || [];
          if (datas.pics !== null) {
            this.dealAbnormalForm.pics.push(datas.pics);
            datas.pics.forEach((item) => {
              if (this.fileList_deal.length < 1) {
                this.fileList_deal.push({
                  name: item.fileId,
                  url: item.url,
                });
              }
            });
          }

          this.dealAbnormalForm = datas;
          // console.log("this.dealAbnormalForm", this.dealAbnormalForm);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getDealTypeList() {
      //  处理类型
      Api.abnormalDealSList().then((res) => {
        if (res.data.status === "success") {
          this.dealType = res.data.result;
        } else {
          console.log(`${res.data.message}--异常处理类型`);
        }
      });
    },
    dealSave() {
      //  异常处理按钮
      let picsArr = [];
      if (this.fileList_deal.length > 0) {
        this.fileList_deal.forEach((item) => {
          picsArr.push(item.name);
        });
      }
      let parm = {
        compensationAmount: this.dealAbnormalForm.compensationAmount || "",
        consigneeAddress: this.dealAbnormalForm.consigneeAddress || "",
        consigneeName: this.dealAbnormalForm.consigneeName || "",
        consigneePhone: this.dealAbnormalForm.consigneePhone || "",
        courierNumber: this.dealAbnormalForm.courierNumber || "",
        exceptionId: this.dealAbnormalForm.exceptionId || "",
        expressAmount: this.dealAbnormalForm.expressAmount || "",
        expressCompany: this.dealAbnormalForm.expressCompany || "",
        handleComment: this.dealAbnormalForm.handleComment || "",
        handleTime: this.dealAbnormalForm.handleTime || "",
        handleTypeName: this.dealAbnormalForm.handleTypeName || "",
        handlerUserId: this.dealAbnormalForm.handlerUserId || "",
        isHandle: this.dealAbnormalForm.isHandle || 0,
        isIndemnity: this.dealAbnormalForm.isIndemnity || 0,
        isReturnGoods: this.dealAbnormalForm.isReturnGoods || 0,
        pics: picsArr,
        returnOrderNo: this.dealAbnormalForm.courierNumber || "",
      };
      let sign = tools.getSign(parm);
      parm.sign = sign;
      Api.exceptionHandleV2(parm).then((res) => {
        if (res.data.status === "success") {
          this.$message.success(res.data.message || "");
          setTimeout(() => {
            tools.closePage();
            this.reload();
          }, 500);
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.formBox {
  width: 100%;
  ._header {
    display: flex;
    align-items: center;
    justify-content: center;
    /deep/ .el-input__inner,
    .el-select {
      width: 300px;
      height: 30px;
    }
    margin-bottom: 10px;
  }
  .btns {
    margin: 20px;
  }
}

.newForm {
  .queryItem1 {
    width: 36%;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

.addFormClass {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .queryItem1 {
    width: 30%;
    margin: 0 3% 12px 0;
  }
}

.queryItem {
  width: 36%;
  margin: 0 auto;
  /deep/ .el-form-item {
    margin: 0;
  }
  /deep/ .el-input__inner {
    height: 30px;
  }
}

.picBox {
  width: 95%;
  margin: 0 auto;
}

.hide .el-upload--picture-card {
  display: none;
}
</style>
